import React from 'react';
import { Link } from 'gatsby';
import { Box, PseudoBox, Text } from '@chakra-ui/core';

const ImageLink = ({ imageUrl, to, children, backgroundPosition, ...rest }) => {
  return (
    <Box
      position="relative"
      margin={{ base: '0 10px' }}
      {...rest}
      overflow="hidden"
    >
      <Link to={to}>
        <PseudoBox
          width="100%"
          height="100%"
          backgroundImage={`url(${imageUrl})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={backgroundPosition}
          transition="all .5s"
          _hover={{ transform: 'scale(1.05)' }}
        ></PseudoBox>
        <Box
          position="absolute"
          bottom="10px"
          left="10px"
          right="10px"
          textAlign={{ base: 'right', sm: 'left' }}
        >
          <Text color="white" fontSize="4xl" fontWeight="600">
            {children}
          </Text>
        </Box>
      </Link>
    </Box>
  );
};

export default ImageLink;
