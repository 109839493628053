import React from 'react';
import { Grid } from '@chakra-ui/core';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ImageLink from '../components/ImageLink';

import AboutImage from '../images/about.jpg';
import ListenImage from '../images/listen.jpg';
import BookingImage from '../images/booking.jpg';
import WritingImage from '../images/writing.jpg';
import InstagramImage from '../images/instagram.jpg';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(6, 1fr)' }}
        templateRows={{
          base: 'repeat(5, 50vw)',
          md: 'repeat(2, calc(50vh - 140px))',
          lg: 'repeat(2, calc(50vh - 60px))',
        }}
        gridGap="10px 10px"
        width="100%"
      >
        <ImageLink
          to="/about"
          imageUrl={AboutImage}
          gridColumn={{ base: 'span 1', md: '1 / span 3' }}
          gridRow={{ base: 'auto', md: 1 }}
          backgroundPosition={{ base: 'initial', md: '60% center' }}
        >
          About
        </ImageLink>
        <ImageLink
          to="/listen"
          imageUrl={ListenImage}
          gridColumn={{ base: 'span 1', md: '4 / span 3' }}
          gridRow={{ base: 'auto', md: 1 }}
        >
          Listen
        </ImageLink>
        <ImageLink
          to="/booking"
          imageUrl={BookingImage}
          gridColumn={{ base: 'span 1', md: '1 / span 2' }}
          gridRow={{ base: 'auto', md: 2 }}
          backgroundPosition={{ base: 'center 30%' }}
        >
          Booking
        </ImageLink>
        <ImageLink
          to="/writing"
          imageUrl={WritingImage}
          gridColumn={{ base: 'span 1', md: '3 / span 2' }}
          gridRow={{ base: 'auto', md: 2 }}
          backgroundPosition={{ base: 'center 15%' }}
        >
          Writing
        </ImageLink>
        <ImageLink
          to="/contact"
          imageUrl={InstagramImage}
          gridColumn={{ base: 'span 1', md: '5 / span 2' }}
          gridRow={{ base: 'auto', md: 2 }}
          backgroundPosition={{ base: 'center 25%' }}
        >
          Contact
        </ImageLink>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
